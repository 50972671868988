import Vue from "vue";
import VueRouter from "vue-router";
import home from '@/view/home/home'

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        component: home,
        meta: {
            title: "质文教育-一个专注于分享的助力站"
        },
        children: [
            {
                path: "/",
                component: () => import(/* webpackChunkName: "group-home" */ "@/view/home/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {

                path: "/links",
                component: () => import(/* webpackChunkName: "group-link" */ "@/view/link/index.vue"),
                meta: {
                    title: "友情链接"
                }
            },
            {
                path: "/article/:articleId",
                component: () => import(/* webpackChunkName: "group-article" */ "@/view/article/index.vue"),
            },
            {
                path: "/material/:materialId",
                component: () => import(/* webpackChunkName: "group-material" */ "@/view/material/index.vue"),
            },
            {
                path: "/message",
                component: () => import(/* webpackChunkName: "group-message" */ "@/view/message/index.vue"),
                meta: {
                    title: "留言板"
                }
            },
            {
                path: "/about",
                component: () => import(/* webpackChunkName: "group-about" */ "@/view/about/index.vue"),
                meta: {
                    title: "关于本站"
                }
            },
            {
                path: "/newposts",
                component: () => import(/* webpackChunkName: "group-article" */ "@/view/article/Add"),
            },
            {
                path: "/openReport",
                component: () => import(/* webpackChunkName: "group-ai" */ "@/view/ai/openReport"),
            },
            {
                path: "/paperAbstract",
                component: resolve => require(["@/view/ai/paperAbstract"], resolve),
            },
            {
                path: "/paper",
                component: resolve => require(["@/view/ai/paper"], resolve),
            },
            {
                path: "/ppt",
                component: resolve => require(["@/view/ai/ppt"], resolve),
            },
            {
                path: "/newmaterials",
                component: () => import(/* webpackChunkName: "group-material" */ "@/view/material/Add"),
            },
            {
                path:'/check-data',
                component: () => import(/* webpackChunkName: "group-check" */ "@/view/check/checkContent"),
            },
            {
                path:'/check-content',
                component: () => import(/* webpackChunkName: "group-check" */ "@/view/check/checkContentWan"),
            },
            {
                path: "/res-download",
                component: () => import(/* webpackChunkName: "group-resourceDownload" */ "@/view/resourceDownload/index"),
            },
            {
                path: "/archive",
                component: () => import(/* webpackChunkName: "group-archive" */ "@/view/archive/index"),
                meta: {
                    title: "文章归档"
                }
            },
            {
                path: "/categorys",
                component: () => import(/* webpackChunkName: "group-category" */ "@/view/category/Category.vue"),
                meta: {
                    title: "文章分类"
                }
            },
            {
                path: "/tag",
                component: () => import(/* webpackChunkName: "group-tag" */ "@/view/tag/Tag"),
                meta: {
                    title: "文章标签"
                }
            },
            {
                path: "/say",
                component: () => import(/* webpackChunkName: "group-say" */ "@/view/say/index"),
                meta: {
                    title: "说说"
                }
            },
            {
                path: "/newSays",
                component: () => import(/* webpackChunkName: "group-say" */ "@/view/say/Add"),
                meta: {
                    title: "添加说说"
                }
            },
            {
                path: "/forum",
                component: () => import(/* webpackChunkName: "group-forum" */ "@/view/forum/index"),
                meta: {
                    title: "瞬间"
                }
            },
            {
                path: "/sponsor",
                component: () => import(/* webpackChunkName: "group-sponsor" */ "@/view/sponsor/index"),
                meta: {
                    title: "打赏名单"
                }
            },
            {
                path: "/im",
                component: () => import(/* webpackChunkName: "group-im" */ "@/view/im/index"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/hot",
                component: () => import(/* webpackChunkName: "group-search" */ "@/view/search/Hot.vue"),
                meta: {
                    title: "网站热搜"
                }
            },
            {
                name: "notice",
                path: "/notice",
                component: () => import(/* webpackChunkName: "group-notice" */ "@/view/notice/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/navigation",
                component: () => import(/* webpackChunkName: "group-navigation" */ "@/view/navigation/index.vue"),
                meta: {
                    title: "网站导航"
                }
            },
            {
                path: "/user",
                component: () => import(/* webpackChunkName: "group-user" */ "@/view/user/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/vip",
                component: () => import(/* webpackChunkName: "group-user" */ "@/view/user/components/OpenVip.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/addBalance",
                component: () => import(/* webpackChunkName: "group-user" */ "@/view/user/components/AddBalance.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/user_main",
                component: () => import(/* webpackChunkName: "group-user" */ "@/view/user/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/check",
                component: () => import(/* webpackChunkName: "group-check" */ "@/view/check/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/down",
                component: () => import(/* webpackChunkName: "group-down" */ "@/view/down/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
            {
                path: "/ai",
                component: () => import(/* webpackChunkName: "group-ai" */ "@/view/ai/index.vue"),
                meta: {
                    title: "质文教育-一个专注于分享的助力站"
                }
            },
        ],
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "group-404" */ "@/view/404/404.vue"),
    }
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes
});
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router;
